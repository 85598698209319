// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { UserManagementModule } from './user-management/user-management.module';
import { NewsModule } from './news/news.module';
import { ConfigurationModule } from './config/config.module';
import { StatisticsModule } from './stats/stats.module';
import { MyProfileModule } from './my-profile/my-profile.module';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    CoreModule,
    PartialsModule,
    UserManagementModule,
    NewsModule,
    ConfigurationModule,
    StatisticsModule,
    MyProfileModule,
    MatDialogModule
  ],
  providers: [],
  entryComponents: [
  ],
})
export class PagesModule {
}
