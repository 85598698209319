// Angular
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import * as d3 from "d3-scale-chromatic";

@Component({
  selector: 'kt-widget-trends',
  templateUrl: './widget-trends.component.html',
  styleUrls: ['widget-trends.component.scss']
})
export class WidgetTrendsComponent {
  @Input() cssClasses = '';
  _newsList: any[] = [];
  @Input()
  set newsList(news: any[]) {
    if (news) {
      this._newsList = news;

      for (let item of this._newsList) {
        if (!item.sentiment) {
          item.sentiment = {
            sentiment_score: 0
          }
        }
        if (item.sentiment.sentiment_score > 5) {
          item.sentiment.sentiment_score = 5;
        }
        item.sentiment.color = d3.interpolateRdYlGn(item.sentiment.sentiment_score / 5);
      }
    }
  }

  get newsList(): any[] {
    return this._newsList;
  }
  @Input() title: string;

  @Output() selectedNews = new EventEmitter<any>();

  constructor() {

  }

  onClickMoreNews() {
    this.selectedNews.emit({ id: -1, source: this.newsList[0].source });
  }

  onClickNews(news) {
    this.selectedNews.emit(news);
  }
}
