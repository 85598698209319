// Angular
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
// NgBootstrap
import { NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// Core module
import { CoreModule } from '../../core/core.module';
// CRUD Partials
import {
  ActionNotificationComponent,
  AlertComponent,
  DeleteEntityDialogComponent,
  FetchEntityDialogComponent,
  UpdateStatusDialogComponent,

} from './content/crud';
// Layout partials
import {
  ContextMenu2Component,
  ContextMenuComponent,
  LanguageSelectorComponent,
  NotificationComponent,
  QuickPanelComponent,
  QuickUserPanelComponent,
  ScrollTopComponent,
  SearchResultComponent,
  SplashScreenComponent,
  Subheader1Component,
  SubheaderSearchComponent,
  UserProfileComponent,
  UserProfile4Component
} from './layout';
import {
  WidgetOverviewComponent,
  WidgetTagsComponent,
  WidgetTrendsComponent,
  WidgetNewsUrlComponent
} from './content/dashboard-widgets';
// General
import { PortletModule } from './content/general/portlet/portlet.module';
// Extra module
import { WidgetModule } from './content/widgets/widget.module';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { NewsFilterDialogComponent } from './content/modals/news-filter/news-filter.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    ScrollTopComponent,
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    NewsFilterDialogComponent,
    AlertComponent,

    // topbar components
    ContextMenu2Component,
    ContextMenuComponent,
    QuickPanelComponent,
    QuickUserPanelComponent,
    ScrollTopComponent,
    SearchResultComponent,
    SplashScreenComponent,
    Subheader1Component,
    SubheaderSearchComponent,
    LanguageSelectorComponent,
    NotificationComponent,
    UserProfileComponent,
    UserProfile4Component,
    WidgetOverviewComponent,
    WidgetTagsComponent,
    WidgetTrendsComponent,
    WidgetNewsUrlComponent
  ],
  exports: [
    WidgetModule,
    PortletModule,
    ScrollTopComponent,
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    NewsFilterDialogComponent,
    AlertComponent,

    // topbar components
    ContextMenu2Component,
    ContextMenuComponent,
    QuickPanelComponent,
    QuickUserPanelComponent,
    ScrollTopComponent,
    SearchResultComponent,
    SplashScreenComponent,
    Subheader1Component,
    SubheaderSearchComponent,
    LanguageSelectorComponent,
    NotificationComponent,
    UserProfileComponent,
    UserProfile4Component,
    WidgetOverviewComponent,
    WidgetTagsComponent,
    WidgetTrendsComponent,
    WidgetNewsUrlComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    InlineSVGModule,
    CoreModule,
    PortletModule,
    WidgetModule,
    NgApexchartsModule,
    // angular material modules
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,

    // ng-bootstrap modules
    NgbDropdownModule,
    NgbTabsetModule,
    NgbTooltipModule,

    TranslateModule.forChild()
  ],
  entryComponents: [NewsFilterDialogComponent]
})
export class PartialsModule {
}
