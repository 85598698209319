// Angular
import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsService } from 'src/app/core/services/news.service';

@Component({
    selector: 'kt-config',
    templateUrl: './config.component.html',
    styleUrls: ['config.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ConfigComponent implements OnInit {

    allWebsites = false;
    allTwitter = false;

    newsList: any;
    tagList: any[];

    categories: string[] = [];

    selectedTags: string[] = [];

    newTwitterTag: string = "";

    constructor(private cdr: ChangeDetectorRef, private route: ActivatedRoute, private newsService: NewsService) { }

    ngOnInit(): void {
        this.newsService.getAllWebsiteUrls().subscribe(
            response => {
                let catNews = {}
                for (let news of response.data) {
                    if (catNews.hasOwnProperty(news.description)) {
                        catNews[news.description].push({url: news.url, checked: news.checked, id: news.id});
                    } else {
                        catNews[news.description] = [{url: news.url, checked: news.checked, id: news.id}];
                    }
                }
                this.newsList = catNews;
                this.categories = Object.keys(this.newsList);
                this.cdr.detectChanges();
            },
            error => {}
        );

        this.newsService.getAllTwitterTags().subscribe(
            response => {
                this.tagList = response.data;
                this.cdr.detectChanges();
            },
            error => {}
        );
    }

    saveWebsiteURLS() {
        let newsIds = [];
        for (let news in this.newsList) {
            let auxList = this.newsList[news];
            for (let n of auxList) {
                if (n.checked) {
                    newsIds.push(n.id);
                }
            }
        }
        this.newsService.changeWebsiteUrls({news: newsIds}).subscribe(
            response => {},
            error => {}
        );
    }

    saveTwitterTags() {
        this.newsService.changeTwitterTags({tags: this.tagList}).subscribe(
            response => {},
            error => {}
        );
    }

    onAddTwitter() {
        this.tagList.push({checked: true, tag_id: -1, tag_name: this.newTwitterTag});
        this.newTwitterTag = "";
    }

}
