<div class="p-5 overflow-hidden">
    <h2 mat-dialog-title>{{'NEWS.FILTER_TITLE' | translate}}</h2>
    <mat-dialog-content class="overflow-hidden">
        <div class="mb-3">
            <div class="font-weight-bold">{{'NEWS.FILTER_WEBSITES' | translate}}</div>
            <div [perfectScrollbar]="{wheelPropagation: false}"
                [ngStyle]="{'max-height': '50vh', 'min-height': '15vh', 'position': 'relative'}" class="row">
				<!-- <div class="col-3 order-1">
					<mat-checkbox [(ngModel)]="allWebsites">{{'NEWS.FILTER_ALL' | translate}}</mat-checkbox>
				</div> -->
				<div *ngFor="let item of newsList" class="col-3 order-1">
                    <mat-checkbox [(ngModel)]="item.checked">{{item.url}}</mat-checkbox>
                </div>
            </div>
		</div>
		<div class="mb-3">
            <div class="font-weight-bold">{{'NEWS.FILTER_TWITTER' | translate}}</div>
            <div [perfectScrollbar]="{wheelPropagation: false}"
                [ngStyle]="{'max-height': '50vh', 'min-height': '15vh', 'position': 'relative'}" class="row">
				<!-- <div class="col-3 order-1">
					<mat-checkbox [(ngModel)]="allTwitter">{{'NEWS.FILTER_ALL' | translate}}</mat-checkbox>
				</div> -->
				<div *ngFor="let item of tagList" class="col-3 order-1">
                    <mat-checkbox [(ngModel)]="item.checked">{{item.tag}}</mat-checkbox>
                </div>
            </div>
        </div>
        <div class="">
            <div class="font-weight-bold">{{'NEWS.FILTER_KEYWORDS' | translate}}</div>
            <div class="form-group">
                <input type="text" class="form-control col-6" [(ngModel)]="newKeywords" (change)="onChangeNewKeyword()">
                <small class="text-mutted">{{'NEWS.KEYWORDS_LIMITATION' | translate}}</small>
            </div>
            <div class="font-weight-bold">{{'NEWS.SELECTED_KEYWORDS' | translate}}</div>
            <div class="row border border-light p-4">
                <div *ngFor="let item of selectedKeywords" class="order-1">
                    <span class="col-3">{{item}}</span>
                    <span class="badge badge-light- bg-hover-primary-o-1 ml-1" (click)="onClickDeleteKeyword(item)">x</span>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mt-3 justify-content-end">
        <button mat-button mat-dialog-close class="btn btn-secondary">{{'NEWS.FILTER_CANCEL' | translate}}</button>
        <button mat-button [mat-dialog-close]="data" class="btn btn-primary">{{'NEWS.FILTER_DONE' | translate}}</button>
    </mat-dialog-actions>
</div>
<!-- 
<div class="card card-custom"
	[ngClass]="{'card-body-progress' : viewLoading, 'card-body-progress card-body-progress-overlay' : true }">
	<div class="card-header">
		<div class="card-title">
			<h3 class="card-label">{{data.title}}</h3>
		</div>
	</div>
	<div class="form">
		<div class="card-body">
			<div class="card-body-progress">
				<mat-spinner [diameter]="20"></mat-spinner>
			</div>
			<div class="timeline timeline-5 mt-3">
				<div class="timeline-item align-items-start" *ngFor="let message of data.messages">
					<div class="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3"></div>
					<div class="timeline-badge">
						<i class="fa fa-genderless text-{{message.statusCssClass}} icon-xxl"></i>
					</div>
					<div class="timeline-content text-dark-50 mr-5">TEXT
						<span
							class="label label-lg label-light-{{message.statusCssClass}} label-inline">STATUS</span>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer">
			<div class="d-flex justify-content-between">
				<div class="mr-2"></div>
				<div>
					<button type="button" mat-raised-button color="primary" 
						>Update status</button>&nbsp;
					<button type="button" mat-raised-button (click)="onNoClick()">Cancel</button>
				</div>
			</div>
		</div>
	</div>
</div> -->