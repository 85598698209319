// Angular
import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../core/auth/_services/';


@Component({
    selector: 'kt-my-profile',
    templateUrl: './my-profile.component.html',
    styleUrls: ['my-profile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class MyProfileComponent implements OnInit {
    // user$: Observable<any>;

    public user: any = {
        username: '', 
        fullname: '', 
        email: '',
        active: false, 
        created: '',
        password: '******'
      };

    constructor(private cdr: ChangeDetectorRef, private route: ActivatedRoute, private authService: AuthService) { }

    ngOnInit(): void {
        this.authService.getUserByToken().subscribe(
            (result: any) => {
                this.user = result.data;
                this.user.password = '******';
                this.cdr.detectChanges()
            },
            error => {}
        )
    }

    onChangeUserData() {
        this.authService.updateUser(this.user).subscribe(
            (result: any) => {
                this.user = result.data;
                this.user.password = '******';
                this.cdr.detectChanges()
            },
            error => {}
        )
    }

    onDownloadUserData() {
        this.authService.downloadUserInfo().subscribe(
          result => {
            const newBlob = new Blob([result], { type: 'application/octet-stream' });
    
            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob);
              return;
            }
    
            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
    
            const link = document.createElement('a');
            link.href = data;
            link.download = 'user-info.json';
            // this is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
              link.remove();
            }, 100);
          },
          error => {
          }
        );
      }

}
