<!-- begin:: Footer -->
<div class="footer bg-white py-4 d-flex flex-lg-column " id="kt_footer" [ngClass]="footerClasses">
	<div [ngClass]="footerContainerClasses" class=" d-flex flex-column flex-md-row align-items-center justify-content-between">
    <!--begin::Copyright-->
    <div class="text-dark order-2 order-md-1">
			<span class="text-muted font-weight-bold mr-2">{{today | date:'yyyy'}} &copy;</span>
      <a  class="text-dark-75 text-hover-primary">SeMAntic</a>
		</div>
    <!--end::Copyright-->

    <!--begin::Nav-->
    <div class="nav nav-dark order-1 order-md-2">
			
		</div>
	</div>
</div>
<!-- end:: Footer -->
