<div class="d-flex flex-column-fluid flex-center mt-0 mt-lg-0">
	<!--begin::Signup-->
	<div class="login-form login-signup" style="display: block;">
		<div class="text-center mb-5 mb-lg-5">
			<h3 class="font-size-h1">{{ "AUTH.REGISTER.TITLE" | translate }}</h3>
			<p class="text-muted font-weight-bold">{{ "AUTH.REGISTER.DESC" | translate }}</p>
		</div>

		<kt-auth-notice></kt-auth-notice>

		<form class="form" [formGroup]="registerForm" autocomplete="off">
			<div class="form-group py-0">
				<mat-form-field>
					<mat-label>{{ "AUTH.INPUT.FULLNAME" | translate }}</mat-label>
					<input matInput type="text" placeholder="Fullname" formControlName="fullname" />
					<mat-error *ngIf="isControlHasError('fullname','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('fullname','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('fullname','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group py-0">
				<mat-form-field>
					<mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
					<input matInput type="email" placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}"
						formControlName="email" autocomplete="off" />
					<mat-error *ngIf="isControlHasError('email','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','email')">
						<strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group py-0">
				<mat-form-field>
					<mat-label>{{ 'AUTH.INPUT.USERNAME' | translate }}</mat-label>
					<input matInput type="text" placeholder="Username" formControlName="username" />
					<mat-error *ngIf="isControlHasError('username','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('username','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('username','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group py-0">
				<mat-form-field>
					<mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
					<input matInput type="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}"
						formControlName="password" autocomplete="off" />
					<mat-error *ngIf="isControlHasError('password','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group py-0">
				<mat-form-field>
					<mat-label>{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}</mat-label>
					<input matInput type="password" placeholder="{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}"
						formControlName="confirmPassword" autocomplete="off" />
					<mat-error *ngIf="isControlHasError('confirmPassword','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
					<mat-error
						*ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
						<strong>{{ "AUTH.INPUT.PASSWD_ERROR" | translate }}</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group py-0">
				<mat-checkbox name="agree" formControlName="agree">
					{{ 'AUTH.REGISTER.AGREE' | translate }}<a href="javascript:;">{{ 'AUTH.REGISTER.TERMS' | translate }}</a>
				</mat-checkbox>
			</div>

			<!--begin::Action-->
			<div class="form-group d-flex flex-wrap flex-center">
				<button (click)="submit()" id="kt_login_signup_submit"
					class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
					[ngClass]="{'spinner spinner-right spinner-md spinner-light': loading}">{{ 'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}</button>
				<a href="/auth/login" id="kt_login_signup_cancel"
					class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">{{ 'AUTH.GENERAL.CANCEL' | translate }}</a>
			</div>
			<!--end::Action-->
		</form>
	</div>
	<!--end::Signup-->
</div>