<!-- <ngx-spinner [fullScreen]="true" type="ball-scale-ripple-multiple" size="large" bdColor="rgba(255,255,255,0.8)" color="#00c4dc">
	<p class="loading">{{'ANALYSIS.GO' | translate}}</p>
</ngx-spinner> -->

<div class="card card-custom flex-row justify-content-between">
	<!-- Body -->
	<div class="card-body col-5 border-right">
		<div *ngIf="!similarNewsTitle">
			<button *ngIf="!filteredNews" type="button" class="btn btn-light-primary btn-block mb-3"
				(click)="onClickFilter()">
				<span class="mr-3">{{'NEWS.FILTER' | translate}}</span>
				<i class="fas fa-filter"></i>
			</button>

			<div *ngIf="filteredNews" class="btn-group btn-block" role="group">
				<button type="button" class="btn btn-light-warning btn-block mb-3"
					(click)="onClickFilter()">
					<span class="mr-3">{{'NEWS.FILTER' | translate}}</span>
					<i class="fas fa-filter"></i>
				</button>
				<button class="btn btn-light-warning mb-3" type="button" (click)="onClickRemoveFilter()" ngbTooltip="{{'NEWS.REMOVE_FILTER' | translate}}">
					<i class="fas fa-broom"></i>
				</button>
			</div>

			<div class="input-group mb-3">
				<input type="text" class="form-control border-primary" [ngClass]="{'border-warning': searchTerms}"
					aria-describedby="basic-addon2" [name]="searchTerms" [(ngModel)]="searchTerms">
				<div class="input-group-append">
					<button class="btn btn-primary" [ngClass]="{'btn-warning': searchTerms}" type="button"
						(click)="onClickSearch()">
						<i class="fas fa-search"></i>
					</button>
				</div>
			</div>
		</div>
		<div *ngIf="similarNewsTitle">
			<span class="font-weight-bold text-dark-75 font-size-h5 mb-5 text-center">
				{{'NEWS.SIMILAR_WITH' | translate}}
			</span>
			<span class="font-weight-bold text-dark-75 font-size-h5 mb-5 text-center">
				{{similarNewsTitle}}
			</span>
			<button type="button" class="btn btn-primary btn-light-primary btn-block mb-3" (click)="onClickBack()">
				<i class="fas fa-angle-double-left"></i>
				<span>{{'NEWS.BACK' | translate}}</span>
			</button>
		</div>

		<!--begin::Item-->
		<div [perfectScrollbar]="{wheelPropagation: false}" [ngStyle]="{'max-height': '100vh', 'position': 'relative'}">
			<div *ngFor="let item of newsList" class="mb-3 d-flex pr-3"
				[ngClass]="{'bg-primary-o-50': item.title === currentNews?.title}">
				<!--begin::Section-->
				<div class="my-auto font-size-h2 cursor-pointer">
					<!-- empty star -->
					<i *ngIf="!item.important" class="far fa-star text-muted"
						(click)="onClickImportantNews(item, true)"></i>
					<!-- full star -->
					<i *ngIf="item.important" class="fas fa-star text-warning"
						(click)="onClickImportantNews(item, false)"></i>
				</div>
				<div (click)="onClickNews(item)" [ngStyle]="{'border-color': item.sentiment?.color}"
					class="pr-4 d-flex align-items-center sem-border w-100">
					<!--begin::Text-->
					<div class="d-flex flex-column flex-grow-1 ml-4">
						<a class="font-size font-weight-bold text-dark-75 text-hover-primary mb-1">{{item.title}}</a>
						<div class="text-muted font-weight-bold">
							<span>{{'NEWS.DATE_CHANGED' | translate}}</span>
							<span>{{item.date_modify | date}}</span>
						</div>
						<div class="text-muted font-weight-bold" *ngIf="item.category">
							<span>{{'NEWS.CATEGORY' | translate}}</span>
							<span>{{item.category}}</span>
						</div>
						<div class="text-muted font-weight-bold" *ngIf="item.keywords">
							<span>{{'NEWS.KEYWORDS' | translate}}</span>
							<span>{{item.keywords}}</span>
						</div>
					</div>

					<!--end::Text-->
				</div>
				<!--end::Section-->
			</div>
		</div>
		<!--end::Item-->
	</div>

	<div class="card-body col-7">
		<div>
			<a href={{currentNews?.url}} target="_blank"
				class="font-weight-bold text-dark-75 font-size-h5 mb-5 text-center">
				{{currentNews?.title}}
			</a>
			<i class="far fa-clone text-primary ml-3 cursor-pointer" ngbTooltip="{{'NEWS.SIMILAR_NEWS' | translate}}"
				(click)="onClickSimilarNews()"></i>
		</div>
		<div class="my-5 row p-0">
			<div class="col sem-font-small">
				<div *ngIf="currentNews?.source_domain" class="mb-3">
					<i class="text-primary fas fa-globe mr-3"></i>
					<span>{{'NEWS.SOURCE' | translate}}</span>
					<span>{{currentNews?.source_domain}}</span>
				</div>
				<div *ngIf="currentNews?.date_modify" class="mb-3">
					<i class="text-primary far fa-calendar-alt mr-3"></i>
					<span>{{'NEWS.DATE_CHANGED' | translate}}</span>
					<span>{{currentNews?.date_modify | date}}</span>
				</div>
				<div *ngIf="currentNews?.authors" class="mb-3">
					<i class="text-primary far fa-id-card mr-3"></i>
					<span>{{'NEWS.AUTHOR' | translate}}</span>
					<span>{{currentNews?.authors}}</span>
				</div>
				<div *ngIf="currentNews?.category" class="mb-3">
					<i class="text-primary fas fa-tags mr-3"></i>
					<span>{{'NEWS.CATEGORY' | translate}}</span>
					<span>{{currentNews?.category}}</span>
				</div>
				<div *ngIf="currentNews?.keywords" class="mb-3">
					<i class="text-primary fas fa-key mr-3"></i>
					<span>{{'NEWS.KEYWORDS' | translate}}</span>
					<span>{{currentNews?.keywords}}</span>
				</div>
				<div *ngIf="currentNews?.fake_news" class="mb-3">
					<i class="text-primary fas fa-hashtag mr-3"></i>
					<span>{{'NEWS.TYPE' | translate}}</span>
					<span class="font-weight-bold">{{currentNews?.fake_news.type}}</span>
					<span>{{'NEWS.CONFIDENCE' | translate}}</span>
					<span>{{currentNews?.fake_news.confidence_percent}}</span>
				</div>
				<div class="sem-font-small" class="mb-3">
					<i class="text-primary fas fa-thermometer-empty mr-3"></i>
					<span>{{'NEWS.VALENCE' | translate}}</span>
					<span class="font-weight-bold"
						[ngStyle]="{'color': currentNews?.sentiment.color}">{{currentNews?.sentiment.sentiment_score | number}}</span>
					<span class="font-weight-bold"> / 5</span>
				</div>
				<div class="d-flex justify-content-between">
					<span>{{'NEWS.NEGATIVE' | translate}}</span>
					<span>{{'NEWS.POSITIVE' | translate}}</span>
				</div>
				<div class="w-100">
					<span #valenceMarker class="sem-marker d-block position-absolute my-auto"></span>
					<img #valenceScale class="sem-valence-scale" src="../../../../assets/media/misc/valence-scale.png"
						alt="valence scale">
				</div>
			</div>
		</div>
		<div class="sem-font-small mb-5">
			<div class="sem-text-underline mb-2">{{'NEWS.SUMMARY_TEXT' | translate}}</div>
			<div class="pre-wrap text-justify pr-3">
				<div class="pre-wrap text-justify pr-3">
					<p *ngFor="let par of processSummary()">
						<span *ngFor="let item of par.paragraphs"
							[ngClass]="{'border-bottom border-2 border-primary mx-1': item.highlight}">{{item.word}}</span>
					</p>
				</div>
			</div>
		</div>
		<div *ngIf="showAllText" class="sem-font-small mb-5">
			<div class="sem-text-underline mb-2">
				<span>{{'NEWS.FULL_TEXT' | translate}}</span>
				<span (click)="onClickTextValence()">
					<i class="text-primary fas fa-thermometer-empty ml-3 cursor-pointer"
						ngbTooltip="{{'NEWS.VALENCE_TOOLTIP' | translate}}"></i>
				</span>
			</div>
			<div [perfectScrollbar]="{wheelPropagation: false}"
				[ngStyle]="{'max-height': '50vh', 'position': 'relative'}">
				<div class="pre-wrap text-justify pr-3">
					<p *ngFor="let par of processMainText()" [ngStyle]="{'background-color': par.style}">
						<span *ngFor="let item of par.paragraphs"
							[ngClass]="{'border-bottom border-2 border-primary mx-1': item.highlight}">{{item.word}}</span>
					</p>
				</div>
			</div>
		</div>
		<div class="d-flex justify-content-center">
			<a *ngIf="!showAllText" class="btn btn-primary btn-sm" role="button"
				(click)="toggleMainText()">{{'NEWS.SHOW_MAIN_TEXT' | translate}}</a>
			<a *ngIf="showAllText" class="btn btn-secondary btn-sm" role="button"
				(click)="toggleMainText()">{{'NEWS.HIDE_MAIN_TEXT' | translate}}</a>
		</div>
	</div>
</div>