/* Imports */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { IParams } from '../params.components';

export let createMapChart = (htmlPlaceholder: string, data: any[], names: string[]) => {
    //Define line colors
    const colors = [
        "#2569b4", "#19a1db", "#d51968", "#f47521", "#f7a81b",
        "#C70039", "#FF5733", "#FF8D1A", "#FFC300", "#EDDD53",
        "#ADD45C", "#57C785", "#00BAAD", "#2A7B9B", "#3D3D6B",
        "#511849", "#900C3F", "#3F900C", "#ffc0cb", "#c71585",
    ]

    let allSeries = [];

    am4core.useTheme(am4themes_animated);

    // Define marker path
    let targetSVG = "M9,0C4.029,0,0,4.029,0,9s4.029,9,9,9s9-4.029,9-9S13.971,0,9,0z M9,15.93 c-3.83,0-6.93-3.1-6.93-6.93S5.17,2.07,9,2.07s6.93,3.1,6.93,6.93S12.83,15.93,9,15.93 M12.5,9c0,1.933-1.567,3.5-3.5,3.5S5.5,10.933,5.5,9S7.067,5.5,9,5.5 S12.5,7.067,12.5,9z";

    // Create map instance
    let chart = am4core.create(htmlPlaceholder, am4maps.MapChart);

    // Set map definition
    chart.geodata = am4geodata_worldLow;

    // Set projection
    chart.projection = new am4maps.projections.Mercator();

    // Add zoom control
    chart.zoomControl = new am4maps.ZoomControl();

    // Set initial zoom
    chart.homeZoomLevel = 2.5;
    chart.homeGeoPoint = {
        latitude: 51,
        longitude: -23
    };

    // Legend
    chart.legend = new am4maps.Legend();
    chart.legend.position = "left";
    chart.legend.scrollable = true;

    // Create map polygon series
    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.exclude = ["AQ"];
    polygonSeries.useGeodata = true;
    polygonSeries.mapPolygons.template.nonScalingStroke = true;
    polygonSeries.hiddenInLegend = true;

    // Configure series
    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.strokeOpacity = 0.5;
    polygonTemplate.nonScalingStroke = true;

    for (let i = 0; i < data.length; i++) {
        // for (let j = 0; j < data[i].length; j++) {
            // create markers
            let imageSeries = chart.series.push(new am4maps.MapImageSeries());
            imageSeries.name = names[i];
            imageSeries.fill = am4core.color(colors[i % colors.length]);

            // define template
            let imageSeriesTemplate = imageSeries.mapImages.template;
            let circle = imageSeriesTemplate.createChild(am4core.Sprite);
            circle.scale = 0.8;
            circle.fill = am4core.color(colors[i % colors.length]);
            circle.path = targetSVG;

            // set propertyfields
            imageSeriesTemplate.propertyFields.latitude = "latitude";
            imageSeriesTemplate.propertyFields.longitude = "longitude";

            imageSeriesTemplate.horizontalCenter = "middle";
            imageSeriesTemplate.verticalCenter = "middle";
            imageSeriesTemplate.align = "center";
            imageSeriesTemplate.valign = "middle";
            imageSeriesTemplate.width = 8;
            imageSeriesTemplate.height = 8;
            imageSeriesTemplate.nonScaling = true;
            imageSeriesTemplate.tooltipText = "{title}";
            imageSeriesTemplate.fill = am4core.color("#000");
            imageSeriesTemplate.background.fillOpacity = 0;
            imageSeriesTemplate.background.fill = am4core.color("#ffffff");
            imageSeriesTemplate.setStateOnChildren = true;
            imageSeriesTemplate.states.create("hover");

            imageSeries.data = Object.values(data[i]);

            allSeries.push(imageSeries);
        // }
    }

    // Create series
    function createSeries(name) {
        var series = chart.series.push(new am4maps.MapLineSeries());
        series.name = name;
        series.strokeWidth = 2;

        let gradient = new am4core.LinearGradient();
        gradient.addColor(am4core.color(colors[5]));
        gradient.addColor(am4core.color(colors[7]));
        gradient.addColor(am4core.color(colors[11]));
        gradient.addColor(am4core.color(colors[13]));
        gradient.rotation = -135;

        series.fill = gradient;

        return series;
    }

    let toggleAll = createSeries("Toggle All");

    toggleAll.events.on("hidden", function () {
        for (let i = 0; i < allSeries.length; i++) {
            allSeries[i].hide();
        }
    });

    toggleAll.events.on("shown", function () {
        for (let i = 0; i < allSeries.length; i++) {
            allSeries[i].show();
        }
    });

}



