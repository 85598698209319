/* Imports */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


export let createBarChart = (htmlPlaceholder: string, data: any[], names: string[], keys) => {

    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    let chart = am4core.create(htmlPlaceholder, am4charts.XYChart);
    chart.colors.step = 2;

    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top';
    chart.legend.paddingBottom = 20;
    chart.legend.labels.template.maxWidth = 95;

    console.log(data);

    let chartData = [];
    let periodList = [];

    for (let i = 0; i < data.length; i ++) {
        let stat = data[i];
        for (let date in stat) {
            if (!periodList.includes(date)) {
                periodList.push(date);
            }
        }
    }

    periodList.sort();

    for (let month of periodList) {
        const entry = {'month': month};
        for (let i = 0; i < data.length; i ++) {
            const dict = data[i];
            entry["value" + i] = dict[month] ? dict[month] : 0;
        }
        chartData.push(entry);
    }

    console.log(chartData);

    chart.data = chartData;

    // Create axes
    // let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    // categoryAxis.dataFields.category = "month";
    // categoryAxis.numberFormatter.numberFormat = "#";
    // categoryAxis.renderer.grid.template.location = 0;
    // categoryAxis.renderer.cellStartLocation = 0.1;
    // categoryAxis.renderer.cellEndLocation = 0.9;

    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.tooltipDateFormat = "yyyy MMM";

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;

    // chart.scrollbarX = new am4charts.XYChartScrollbar();

    // Create series
    function createSeries(field, name) {
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = field;
        series.dataFields.dateX = "month";
        series.name = name;
        series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]\n{dateX.formatDate('MMM, yyyy')}";
        series.columns.template.height = am4core.percent(100);
        series.sequencedInterpolation = true;
    }

    for (let i = 0; i < names.length; i++) {
        createSeries(`value${i}`, names[i]);
    }
}