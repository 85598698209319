<div class="row">
    <div class="col-12 d-flex m-0">
        <div class="col card card-custom card-stretch gutter-b">
            <!-- Header -->
            <div class="card-header border-0">
                <h3 class="card-title font-weight-bolder text-dark">{{'NEWS.FILTER_TITLE' | translate}}</h3>
            </div>
            <!-- Body -->
            <div class="card-body pt-2">
                <div class="">
                    <div class="font-weight-bold">{{'NEWS.FILTER_KEYWORDS' | translate}}</div>
                    <div class="form-group">
                        <input type="text" class="form-control" [(ngModel)]="newKeywords"
                            (change)="onChangeNewKeyword()">
                        <small class="text-mutted">{{'NEWS.KEYWORDS_LIMITATION' | translate}}</small>
                    </div>
                    <div class="font-weight-bold">{{'NEWS.SELECTED_KEYWORDS' | translate}}</div>
                    <div class="row border border-light p-4 m-0">
                        <div *ngFor="let item of selectedKeywords" class="order-1">
                            <span class="col-3">{{item}}</span>
                            <span class="badge badge-light- bg-hover-primary-o-1 ml-1"
                                (click)="onClickDeleteKeyword(item)">x</span>
                        </div>
                    </div>
                </div>

                <div class="d-flex mt-4 p-0">
                    <div class="input-group mb-3 mr-4">
                        <span class="my-auto mr-2">{{'DASHBOARD.FROM' | translate}}</span>
                        <input type="text" class="form-control" ngbDatepicker #start="ngbDatepicker"
                            (dateSelect)="onStartSelect($event)">
                        <div class="input-group-append">
                            <button class="btn" (click)="start.toggle()"><i class="far fa-calendar-alt"></i></button>
                        </div>
                    </div>

                    <div class="input-group mb-3">
                        <span class="my-auto mr-2">{{'DASHBOARD.TO' | translate}}</span>
                        <input type="text" class="form-control" ngbDatepicker #end="ngbDatepicker"
                            (dateSelect)="onEndSelect($event)">
                        <div class="input-group-append">
                            <button class="btn" (click)="end.toggle()"><i class="far fa-calendar-alt"></i></button>
                        </div>
                    </div>
                </div>
                <button type="button" class="btn btn-primary btn-lg btn-block"
                    (click)="onClickFilter()">{{'NEWS.FILTER' | translate}}</button>
            </div>
        </div>
        <div class="col-4" *ngIf="showCharts">
            <kt-widget-trends cssClasses="card-stretch gutter-b"
                [newsList]="newsList" title="{{'MENU.NEWS' | translate}}"></kt-widget-trends>
        </div>
    </div>


    <!-- linechart -->
    <div class="col-12 d-flex m-0 p-0"  *ngIf="showCharts">
        <div class="col-12">
            <div class="card card-custom card-stretch gutter-b">
                <!-- Header -->
                <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">{{'DASHBOARD.MENTIONS' | translate}}</h3>
                </div>
                <!-- Body -->
                <div class="card-body pt-2">
                    <div id="barChartContainer" class="sem-chart"></div>
                </div>
            </div>
        </div>
        
    </div>

    <!-- map -->
    <div class="col-12" *ngIf="showCharts">
        <div class="card card-custom card-stretch gutter-b">
            <!-- Header -->
            <div class="card-header border-0">
                <h3 class="card-title font-weight-bolder text-dark">{{'DASHBOARD.MAP' | translate}}</h3>
            </div>
            <!-- Body -->
            <div class="card-body pt-2">
                <div id="mapChartContainer" class="sem-chart-big"></div>
            </div>
        </div>
    </div>
</div>