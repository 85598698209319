// Romanian
export const locale = {
  lang: 'ro',
  data: {
    COOKIE: {
      HEADER: 'Cookies folosite pe acest website',
      MESSAGE: 'Prin utilizarea serviciilor noastre, vă exprimați acordul cu privire la utilizarea cookies.',
      DISMISS: 'Am înțeles',
      ALLOW: 'Sunt de acord',
      DENY: 'Nu, mulțumesc',
      LINK: 'Mai multe detalii',
      POLICY: 'Politica de utilizare cookies'
    },
    NEWS: {
      SOURCE: 'Sursa: ',
      AUTHOR: 'Autori: ',
      VALENCE: 'Valența: ',
      CATEGORY: 'Categorie: ',
      KEYWORDS: 'Cuvinte cheie: ',
      TYPE: 'Tip: ',
      DATE_CHANGED: 'Data ultimei modificări: ',
      SHOW_MAIN_TEXT: 'Arată textul știrii',
      HIDE_MAIN_TEXT: 'Ascunde textul știrii',
      SUMMARY_TEXT: 'Sumarul știrii',
      FULL_TEXT: 'Textul complet al știrii',
      FILTER_TITLE: 'Criterii de filtrare',
      FILTER_WEBSITES: 'Selectați websiteurile: ',
      FILTER_TWITTER: 'Selectați tagurile Twitter:',
      FILTER_ALL: 'Toate',
      FILTER_KEYWORDS: 'Filtrați după cuvinte cheie: ',
      FILTER_CANCEL: 'Anulează',
      FILTER_DONE: 'Filtrează',
      FILTER: 'Filtrează',
      KEYWORDS_LIMITATION: 'Introduceți cuvintele cheie separate de virgulă',
      SELECTED_KEYWORDS: 'Cuvinte cheie selectate: ',
      POSITIVE: 'Pozitiv',
      NEGATIVE: 'Negativ',
      CONFIDENCE: ' cu o certitudine de ',
      TRUE: 'Adevarat',
      PLAUSIBLE: 'Plauzibil',
      TENDENTIOUS: 'Tendențios',
      FICTIONAL: 'Ficțional',
      VALENCE_TOOLTIP: 'Analiza valenței textului la nivel de paragraf',
      SIMILAR_NEWS: 'Știri similare',
      MORE: 'Mai multe știri',
      CONFIGURE_TWITTER: 'Configurare taguri Twitter',
      CONFIGURE_WEBSITES: 'Configurare URL websiteuri',
      SEARCH: 'Căutare',
      TWITTER_ADD: 'Adaugă tag nou',
      CONFIG_TITLE: 'Configurare',
      SIMILAR_WITH: 'Similar cu: ',
      REMOVE_FILTER: 'Șterge filtrul',
      BACK: 'Înapoi',
      NO_DATA: 'Nu există informații pentru criteriile selectate.'
    },
    DASHBOARD: {
      LATEST_NEWS: 'Cele mai recente știri',
      CONFIG_TWITTER: 'Configurare taguri',
      TWITTER_TAGS: 'Taguri Twitter',
      NEWS_VALENCE: 'Polaritatea știrilor',
      MENTIONS: 'Numărul de mențiuni în timp',
      NEWS_URL: 'Websiteuri urmărite',
      FROM: 'De la: ',
      TO: 'Până la: ',
      MAP: 'Harta entităților',
      SELECT_ENTITY: 'Selectați entitatea:',
      TRENDS: 'Tendințe'
    },
    TRANSLATOR: {
      SELECT: 'Selectează limba',
    },
    MENU: {
      DASHBOARD: 'Dashboard',
      NEWS: 'Știri',
      ANALYTICS: 'Statistici',
      CONFIG: 'Configurare',
      MY_PROFILE: 'Profilul Meu'
    },
    AUTH: {
      GENERAL: {
        OR: 'Sau',
        SUBMIT_BUTTON: 'Confirmare',
        NO_ACCOUNT: 'Nu ai cont?',
        SIGNUP_BUTTON: 'Înregistrează-te',
        FORGOT_BUTTON: 'Am uitat parola',
        BACK_BUTTON: 'Înapoi',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
        CANCEL: 'Anulare'
      },
      LOGIN: {
        TITLE: 'Login',
        DESC: 'Introduceți numele de utilizator și parola',
        BUTTON: 'Înregistrare',
      },
      FORGOT: {
        TITLE: 'Ai uitat parola?',
        DESC: 'Introduceți adresa de email pentru a reseta parola.',
        SUCCESS: 'Contul a fost resetat cu succes. Verificați adresa de email pentru a obține noua parolă.'
      },
      REGISTER: {
        TITLE: 'Înregistrare',
        DESC: 'Introdu detaliile pentru a-ți crea cont.',
        SUCCESS: 'Contul a fost înregistrat cu succes.',
        AGREE: 'Sunt de acord cu ',
        TERMS: 'termenele și condițiile'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Nume complet',
        PASSWORD: 'Parolă',
        CONFIRM_PASSWORD: 'Confirmați Parola',
        USERNAME: 'Nume de utilizator'
      },
      VALIDATION: {
        INVALID: '{{name}} nu este valid.',
        REQUIRED: '{{name}} este necesar.',
        MIN_LENGTH: '{{name}} lungimea minimă este {{min}}.',
        AGREEMENT_REQUIRED: 'Este necesară acceptarea termenelor și condițiilor.',
        NOT_FOUND: 'Câmpul {{name}} nu a fost găsit.',
        INVALID_LOGIN: 'Informațiile completate nu sunt corecte.',
        REQUIRED_FIELD: 'Câmp necesar.',
        MIN_LENGTH_FIELD: 'Lungimea minimă este:',
        MAX_LENGTH_FIELD: 'Lungimea maximp este:',
        INVALID_FIELD: 'Câmpul nu este valid.',
        PASSWD: 'Parola și parola confirmată sunt diferite.'
      }
    },
    MISC: {
      USER_PROFILE: 'Profilul Utilizatorului',
      MY_PROFILE: 'Profilul Meu',
      SIGN_OUT: 'Ieșire',
      ACCOUNT_SETTINGS: 'Setările contului și mai multe informații',
      UPDATE: 'modifică',
      CREATED_ON: 'Creat la data: ',
      DOWNLOAD_USER: 'Date utilizator',
      HELLO: 'Bună'
    }
  }
};
