export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: [
        {
          title: 'Dashboard',
          root: true,
          alignment: 'left',
          page: '/dashboard',
          translate: 'MENU.DASHBOARD'
        },
        {
          title: 'My profile',
          root: true,
          alignment: 'left',
          page: '/my-profile',
          translate: 'MENU.MY_PROFILE'
        },
        {
          title: 'News',
          root: true,
          alignment: 'left',
          page: '/news',
          translate: 'MENU.NEWS'
        },
        {
          title: 'Analytics',
          root: true,
          alignment: 'left',
          page: '/stats',
          translate: 'MENU.ANALYTICS'
        },
        {
          title: 'Configuration',
          root: true,
          alignment: 'left',
          page: '/config',
          translate: 'MENU.CONFIG'
        },
        
      ]
    },
    aside: {
      self: {},
      items: [
        {section: 'Overview'},
        {
          title: 'Dashboard',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          page: '/dashboard',
          translate: 'MENU.DASHBOARD',
          bullet: 'dot',
        },
        {
          title: 'My profile',
          root: true,
          icon: 'flaticon2-user-1',
          page: '/my-profile',
          translate: 'MENU.MY_PROFILE',
          bullet: 'dot',
        },
        {section: 'News'},
        {
          title: 'News',
          root: true,
          icon: 'flaticon2-website',
          page: '/news',
          translate: 'MENU.NEWS',
          bullet: 'dot',
        },
        {
          title: 'Analytics',
          root: true,
          icon: 'flaticon-analytics',
          page: '/stats',
          translate: 'MENU.ANALYTICS',
          bullet: 'dot',
        },
        {section: 'Configuration'},
        {
          title: 'Configuration',
          root: true,
          icon: 'flaticon-cogwheel-2',
          page: '/config',
          translate: 'MENU.CONFIG',
          bullet: 'dot',
        }      
       
        // {section: 'Applications'},
        // {
        //   title: 'User Management',
        //   root: true,
        //   bullet: 'dot',
        //   icon: 'flaticon2-user-outline-symbol',
        //   submenu: [
        //     {
        //       title: 'Users',
        //       page: '/user-management/users'
        //     },
        //     {
        //       title: 'Roles',
        //       page: '/user-management/roles'
        //     }
        //   ]
        // }
      ]
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
