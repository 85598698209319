import { ChangeDetectorRef } from '@angular/core';
// Angular
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
import { Logout } from '../../../../../core/auth';
import { AuthService } from '../../../../../core/auth/_services/';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
@Component({
  selector: 'kt-user-profile4',
  templateUrl: './user-profile4.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class UserProfile4Component implements OnInit {

  @Input() avatar = true;
  @Input() greeting = true;
  @Input() badge: boolean;
  @Input() icon: boolean;

  currentUser = {fullname: ''};

  /**
   * Component constructor
   *
   * @param store: Store<AppState>
   */
  constructor(private store: Store<AppState>, private authService: AuthService, private cdr: ChangeDetectorRef) {
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.authService.getUserByToken().subscribe(
      (result: any) => {
        this.currentUser = result.data;
        this.cdr.detectChanges()
      },
      error => { }
    )
  }

  /**
   * Log out
   */
  logout() {
    this.store.dispatch(new Logout());
  }
}
