// Angular
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
// RxJS
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// import { AppState } from '../../../../core/reducers';
// import { AuthService } from '../../../../core/auth';
// import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class HttpErrorInterceptService implements HttpInterceptor {

    constructor(private router: Router) { }

    // intercept request and add token
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        // const authService = this.injector.get(AuthService);

        return next.handle(request).pipe(catchError(err => {
            if ([401, 403].includes(err.status)) {
                // auto logout if 401 or 403 response returned from api
                // authService.logout()
                localStorage.removeItem(environment.authTokenKey);
                localStorage.removeItem(environment.refreshTokenKey);
                this.router.navigate(['/login']);
                // location.reload();
            }

            const error = (err && err.error && err.error.message) || err.statusText || err.msg;
            console.error(err);
            return throwError(error);
        }))


    }
}
