import { Observable, from, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable()
export class NewsService {
  
	constructor(private http: HttpClient) { }
	public getTrends(start, end): Observable<any> {
		const url = environment.BASE_URL + environment.API_NEWS + environment.TRENDS;
		return this.generalPost(url, {start, end});
	}
	
	public getValence(): Observable<any> {
		const url = environment.BASE_URL + environment.API_NEWS + environment.VALENCE;
		return this.generalGet(url);
	}

	public markImportant(esId: string): Observable<any> {
		const url = environment.BASE_URL + environment.API_NEWS + environment.IMPORTANT;
		return this.generalPost(url, {'es_id': esId});
	}

	public getMentionsList(start, end, keywords): Observable<any> {
		const url = environment.BASE_URL + environment.API_NEWS + environment.MENTIONS;
		let params = {start, end, keywords}
		return this.generalPost(url, params);
	}

	public sentimentAnalysis(text, valence): Observable<any> {
		const url = environment.BASE_URL + environment.API_NEWS + environment.SENTIMENT;
		return this.generalPost(url, {text: text, valence: valence});
	} 

	public getSimilarNews(keywords): Observable<any> {
		const url = environment.BASE_URL + environment.API_NEWS + environment.SIMILAR;
		return this.generalPost(url, {keywords});
	}

    public getWebsiteNews(min=-1, size=-1): Observable<any> {
        // /api/news/website
        let limit = '';
        if (min >= 0 && size >= 0) {
            limit = `?start=${min}&size=${size}`;
        }
        const url = environment.BASE_URL + environment.API_NEWS + limit;
        return this.generalGet(url);
	}
	
	public getNewsBy(keywords, urls, tags, searchTerms): Observable<any> {
		const url = environment.BASE_URL + environment.API_NEWS + environment.FILTER;
		return this.generalPost(url, {keywords, urls, tags, searchTerms});
	} 

    public getTwitterTags(): Observable<any> {
        const url = environment.BASE_URL + environment.API_CONFIG + environment.TWITTER;
        return this.generalGet(url);
        // /api/config/twitter
	}
	
	public getAllTwitterTags(): Observable<any> {
        const url = environment.BASE_URL + environment.API_CONFIG + environment.TWITTER + '?all=true';
        return this.generalGet(url);
        // /api/config/twitter
    }

    public changeTwitterTags(params): Observable<any> {
        // post
        // /api/config/twitter
        const url = environment.BASE_URL + environment.API_CONFIG + environment.TWITTER;
        return this.generalPost(url, params);
    }

    public getWebsiteUrls(): Observable<any> {
        const url = environment.BASE_URL + environment.API_CONFIG + environment.WEBSITE;
        return this.generalGet(url);
        // /api/config/website
	}
	
	public getAllWebsiteUrls(): Observable<any> {
        const url = environment.BASE_URL + environment.API_CONFIG + environment.WEBSITE + '?all=true';
        return this.generalGet(url);
        // /api/config/website
    }

    public changeWebsiteUrls(params): Observable<any> {
        // /api/config/website
        // put
        const url = environment.BASE_URL + environment.API_CONFIG + environment.WEBSITE;
        return this.generalPut(url, params);
    }


	private generalGet(url): Observable<any> {
		return this.http.get<any>(url).pipe(
			map((result: any) => {
				console.log(result);
				return result;
			}),
			catchError(err => {
				this.handleError('news', []);
				return throwError(err);
			})
		);
	}

	private generalPut(url, params): Observable<any> {
		return this.http.put<any>(url, params).pipe(
			map((result: any) => {
				console.log(result);
				return result;
			}),
			catchError(err => {
				this.handleError('news', []);
				return throwError(err);
			})
		);
	}

	private generalPost(url, params): Observable<any> {
		return this.http.post<any>(url, params).pipe(
			map((result: any) => {
				console.log(result);
				return result;
			}),
			catchError(err => {
				this.handleError('news', []);
				return throwError(err);
			})
		);
	}

	private generalDelete(url): Observable<any> {
		return this.http.delete<any>(url).pipe(
			map((result: any) => {
				console.log(result);
				return result;
			}),
			catchError(err => {
				this.handleError('news', []);
				return throwError(err);
			})
		);
	}

	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// TODO: better job of transforming error for user consumption
			console.log(`${operation} failed: ${error.message}`);

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}
}
