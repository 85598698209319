<div class="row">
    <div class="col-12">
        <div class="card card-custom card-stretch gutter-b">
            <!-- Header -->
            <div class="card-header border-0">
                <h3 class="card-title font-weight-bolder text-dark">{{'NEWS.CONFIGURE_WEBSITES' | translate}}</h3>
            </div>
            <!-- Body -->
            <div class="card-body pt-2">
                <div class="mb-3">
                    <!-- <div class="row p-0 m-0">
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="{{'NEWS.SEARCH' | translate}}">
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button" id="button-addon2">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div> -->
                    <div class="row p-0" [perfectScrollbar]="{wheelPropagation: false}"
                        [ngStyle]="{'max-height': '50vh', 'position': 'relative'}">
                        <div class="col-12" *ngFor="let cat of categories">
                            <div class="row mx-0 my-3 p-0">
                                <span class="font-weight-bold">
                                    {{cat}}</span>
                            </div>
                            <div class="row m-0 p-0">
                                <div *ngFor="let item of newsList[cat]" class="col-4 order-1">
                                    <mat-checkbox [(ngModel)]="item.checked">{{item.url}}</mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-primary btn-block mt-3" (click)="saveWebsiteURLS()">OK</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="card card-custom card-stretch gutter-b">
            <!-- Header -->
            <div class="card-header border-0">
                <h3 class="card-title font-weight-bolder text-dark">{{'NEWS.CONFIGURE_TWITTER' | translate}}</h3>
            </div>
            <!-- Body -->
            <div class="card-body pt-2">
                <div class="mb-3">
                    <div class="mb-3">
                        <div class="d-flex row p-0 m-0">
                            <!-- <div class="col-6 m-0 p-0">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" placeholder="{{'NEWS.SEARCH' | translate}}">
                                    <div class="input-group-append">
                                        <button class="btn btn-primary" type="button" id="button-addon2">
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-6">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" [(ngModel)]="newTwitterTag" placeholder="{{'NEWS.TWITTER_ADD' | translate}}">
                                    <div class="input-group-append">
                                        <button class="btn btn-primary" type="button" id="button-addon2" (click)="onAddTwitter()">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- <div class="col-3 order-1">
                                <mat-checkbox [(ngModel)]="allTwitter">{{'NEWS.FILTER_ALL' | translate}}</mat-checkbox>
                            </div> -->
                            <div *ngFor="let item of tagList" class="col-3 order-1">
                                <mat-checkbox [(ngModel)]="item.checked">{{item.tag_name}}</mat-checkbox>
                            </div>
                        </div>
                        <button class="btn btn-primary btn-block mt-3" (click)="saveTwitterTags()">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>