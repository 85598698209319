// Angular
import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import * as barchart from '../../charts/barchart/barchart.component';
import * as mapchart from '../../charts/mapchart/mapchart.component';
import { NewsService } from 'src/app/core/services/news.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'kt-stats',
    templateUrl: './stats.component.html',
    styleUrls: ['stats.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class StatsComponent implements OnInit {

    selectedKeywords: any[] = [];
    newKeywords: string;
    mentionList: any[];
    locationList: any[];
    newsList: any[];
    entityList: string[];
    urlList: any[];
    tagsList: any[];
    showCharts = false;
    start: string;
    end: string;
    keys: any;

    constructor(private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private newsService: NewsService, 
        private translate: TranslateService) { }

    ngOnInit(): void {
        this.newsService.getWebsiteUrls().subscribe(
            result => {
                this.urlList = result.data;
                this.cdr.detectChanges();
            },
            error => { }
        );

        this.newsService.getTwitterTags().subscribe(
            result => {
                this.tagsList = result.data;
                for (let tag of this.tagsList) {
                    tag.weight = tag.twitter_id;
                }
            },
            error => { }
        );

        this.keys = this.translate.instant("MONTHS");
    }

    onStartSelect(date: NgbDateStruct) {
        this.start = `${date.year}-${date.month}-${date.day}`;
     }

    onEndSelect(date: NgbDateStruct) {
        this.end = `${date.year}-${date.month}-${date.day}`;
     }

    onClickDeleteKeyword(item) {
        const index = this.selectedKeywords.indexOf(item);
        this.selectedKeywords.splice(index, 1);
    }

    onChangeNewKeyword() {
        const keywords = this.newKeywords.split(',');
        for (let key of keywords) {
            if (!this.selectedKeywords.includes(key)) {
                this.selectedKeywords.push(key.trim());
            }
        }
        this.newKeywords = '';
    }

    onClickFilter() {
        this.newsService.getMentionsList(this.start, this.end, this.selectedKeywords).subscribe(
            result => {
                this.showCharts = true;
                this.cdr.detectChanges();
                this.entityList = result.data.entities;
                this.mentionList = result.data.mentions;

                barchart.createBarChart('barChartContainer', this.mentionList, this.entityList, this.keys);
                this.newsList = result.data.news;
                this.locationList = result.data.locations
                this.cdr.detectChanges();
                mapchart.createMapChart('mapChartContainer', this.locationList, this.entityList);
            },
            error => {}
        )
    }
}
