<div class="d-flex flex-column flex-root" id="kt_login_wrapper">
	<div class="login login-3 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white" id="kt_login">
		<!--begin::Aside-->
		<div class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
			style="background-image: url(./assets/media/bg/bg-2.png);">
			<!--begin: Aside Container-->
			<div class="d-flex flex-row-fluid flex-column justify-content-between">
				<!--begin: Aside header-->
				<a href="#" class="flex-column-auto mt-5">
					<!-- <img src="./assets/media/logos/logo-letter-1.png" class="max-h-70px" /> -->
				</a>
				<!--end: Aside header-->

				<!--begin: Aside content-->
				<div class="flex-column-fluid d-flex flex-column justify-content-center">
					<!-- custom logo -->
					<!-- <span class="font-size-h1 mb-5">
						<span class="custom-logo custom-logo-1">S</span>
						<span class="custom-logo">e</span>
						<span class="custom-logo custom-logo-2">MA</span>
						<span class="custom-logo">ntic</span>
					</span> -->
					<img src="./assets/media/logos/logo-light.svg" class="max-h-100px" alt="">
					<p class="text-dark opacity-80">
						Proiectul SeMAntic vizează dezvoltarea unui model de analiză și monitorizare a prezenței online a entităților folosind tehnici avansate de prelucrare a limbajului natural. 
						Proiectul presupune dezvoltarea unei platforme în care se pot căuta informații despre o anumită entitate și se vor prezenta articole de știri, comentarii și review-uri în care entitatea este menționată, precum și un scor de polaritate pentru sursele găsite. 
						Sistemul colectează în mod continuu surse de text și permite sumarizarea și clasificarea automată a acestora în diverse categorii. 
					</p>
				</div>
				<!--end: Aside content-->

				<!--begin: Aside footer for desktop-->
				<div class="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
					<div class="opacity-70 font-weight-bold	text-white">
						&copy; 2020 SeMAntic
					</div>
					<!-- <div class="d-flex">
						<a href="#" class="text-white">Privacy</a>
						<a href="#" class="text-white ml-10">Legal</a>
						<a href="#" class="text-white ml-10">Contact</a>
					</div> -->
				</div>
				<!--end: Aside footer for desktop-->
			</div>

		</div>
		<!--begin::Aside-->

		<!--begin::Content-->
		<div class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
			<!--begin::Content header-->
			<div class="d-flex justify-content-center">
				<img style="width: 500px;" src="./assets/media/logos/ue_header.png">
			</div>
			<div
				class="right-0 text-right mt-5flex-column-auto justify-content-center"
			>
			
				<span class="font-weight-bold text-dark-50">
					{{ "AUTH.GENERAL.NO_ACCOUNT" | translate }}
				</span>
				<a
					href="javascript:;"
					routerLink="/auth/register"
					class="font-weight-bold ml-2"
					id="kt_login_signup"
				>{{ "AUTH.GENERAL.SIGNUP_BUTTON" | translate }}</a>
			</div>

			
			<!--end::Content header-->
			
			<router-outlet></router-outlet>
			<!--begin::Content footer for mobile-->
			<div
				class="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
				<div class="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
					&copy; 2020 SeMAntic
				</div>
				<!-- <div class="d-flex order-1 order-sm-2 my-2">
					<a href="#" class="text-dark-75 text-hover-primary">Privacy</a>
					<a href="#" class="text-dark-75 text-hover-primary ml-4">Legal</a>
					<a href="#" class="text-dark-75 text-hover-primary ml-4">Contact</a>
				</div> -->
			</div>
			<!--end::Content footer for mobile-->
		</div>
		<!--end::Content-->
	</div>
</div>