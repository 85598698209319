// USA
export const locale = {
  lang: 'en',
  data: {
    COOKIE: {
      HEADER: 'Cookies used on the website!',
      MESSAGE: 'This website uses cookies to ensure you get the best experience on our website.',
      DISMISS: 'Got it!',
      ALLOW: 'Allow cookies',
      DENY: 'Decline',
      LINK: 'Learn more',
      POLICY: 'Cookie Policy'
    },
    NEWS: {
      SOURCE: 'Source: ',
      AUTHOR: 'Authors: ',
      VALENCE: 'Valence: ',
      CATEGORY: 'Category: ',
      KEYWORDS: 'Keywords: ',
      TYPE: 'Type: ',
      DATE_CHANGED: 'Date changed: ',
      SHOW_MAIN_TEXT: 'Show main text',
      HIDE_MAIN_TEXT: 'Hide main text',
      SUMMARY_TEXT: 'News summary',
      FULL_TEXT: 'Complete text',
      FILTER_TITLE: 'Filter news',
      FILTER_WEBSITES: 'Select websites: ',
      FILTER_TWITTER: 'Select Twitter tags:',
      FILTER_ALL: 'All',
      FILTER_KEYWORDS: 'Filter keywords',
      FILTER_CANCEL: 'Cancel',
      FILTER_DONE: 'Done',
      FILTER: 'Filter',
      KEYWORDS_LIMITATION: 'Please enter keywords separated by a comma',
      SELECTED_KEYWORDS: 'Selected keywords: ',
      POSITIVE: 'Positive',
      NEGATIVE: 'Negative',
      CONFIDENCE: ' with a confidence of ',
      TRUE: 'True',
      PLAUSIBLE: 'Plausible',
      TENDENTIOUS: 'Tendentious',
      FICTIONAL: 'Fictional',
      VALENCE_TOOLTIP: 'Analyze the text valence at paragraph level',
      SIMILAR_NEWS: 'Similar news',
      MORE: 'More news',
      CONFIGURE_TWITTER: 'Configure Twitter tags',
      CONFIGURE_WEBSITES: 'Configure website URLs',
      SEARCH: 'Search',
      TWITTER_ADD: 'Add new tag',
      CONFIG_TITLE: 'Configure',
      SIMILAR_WITH: 'Similar with: ',
      REMOVE_FILTER: 'Remove filter',
      BACK: 'Back',
      NO_DATA: 'There is no data for the selected criteria.'
    },
    DASHBOARD: {
      LATEST_NEWS: 'Latest News',
      TWITTER_TAGS: 'Twitter Tags',
      CONFIG_TWITTER: 'Configure Tags',
      NEWS_VALENCE: 'News Valence',
      MENTIONS: 'Number of Mentions Over Time',
      NEWS_URL: 'Followed websites',
      FROM: 'From: ',
      TO: 'To: ',
      MAP: 'Entities Map',
      SELECT_ENTITY: 'Select entity: ',
      TRENDS: 'Trends'
    },
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
      NEWS: 'News',
      ANALYTICS: 'Analytics',
      CONFIG: 'Configuration',
      MY_PROFILE: 'My Profile'
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
        CANCEL: 'Cancel'
      },
      LOGIN: {
        TITLE: 'Login Account',
        DESC: 'Enter your username and password',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset. Check your email for the new password.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.',
        TERMS: 'I agree the ',
        AGREE: 'terms & conditions'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The provided information is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
        PASSWD: 'Passsword and ConfirmPassword didn\'t match'
      }
    },
    MISC: {
      USER_PROFILE: 'User Profile',
      MY_PROFILE: 'My Profile',
      SIGN_OUT: 'Sign out',
      ACCOUNT_SETTINGS: 'Account settings and more',
      UPDATE: 'update',
      CREATED_ON: 'Account created on:',
      DOWNLOAD_USER: 'User data',
      HELLO: 'Hi'
    }
  }
};
