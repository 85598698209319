import { Subscription } from 'rxjs';
// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from './core/_base/layout';
// language list
import { locale as enLang } from './core/_config/i18n/en';
import { locale as roLang } from './core/_config/i18n/ro';

import { NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[kt-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  // Public properties
  title = 'SeMAntic';
  loader: boolean;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  /**
   * Component constructor
   *
   * @param translationService: TranslationService
   * @param router: Router
   * @param layoutConfigService: LayoutConfigService
   * @param splashScreenService: SplashScreenService
   */
  constructor(
    private translationService: TranslationService, // from metronic
    private translate: TranslateService, // from ngx-translate
    private router: Router,
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService,
    private ccService: NgcCookieConsentService,) {

    // register translations
    this.translationService.loadTranslations(enLang, roLang);

    this.changeCookieInfo();

		this.translate.onLangChange.subscribe(
			(params: LangChangeEvent) => {
				this.changeCookieInfo();
			});
  }

  changeCookieInfo() {
		this.translate
			.get(['COOKIE.HEADER', 'COOKIE.MESSAGE', 'COOKIE.DISMISS', 'COOKIE.ALLOW', 'COOKIE.DENY', 'COOKIE.LINK', 'COOKIE.POLICY'])
			.subscribe(data => {

				this.ccService.getConfig().content = this.ccService.getConfig().content || {};
				// Override default messages with the translated ones
				this.ccService.getConfig().content.header = data['COOKIE.HEADER'];
				this.ccService.getConfig().content.message = data['COOKIE.MESSAGE'];
				this.ccService.getConfig().content.dismiss = data['COOKIE.DISMISS'];
				this.ccService.getConfig().content.allow = data['COOKIE.ALLOW'];
				this.ccService.getConfig().content.deny = data['COOKIE.DENY'];
				this.ccService.getConfig().content.link = data['COOKIE.LINK'];
				this.ccService.getConfig().content.policy = data['COOKIE.POLICY'];

				this.ccService.destroy(); // remove previous cookie bar (with default messages)
				this.ccService.init(this.ccService.getConfig()); // update config with translated messages
			});

	}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    // enable/disable loader
    this.loader = this.layoutConfigService.getConfig('page-loader.type');

    const routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);

    // subscribe to cookieconsent observables to react to main events
		this.ccService.statusChange$.subscribe(
			(event: NgcStatusChangeEvent) => {
				if (!this.ccService.hasConsented()) {
					this.ccService.open();
				}
			});
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
