import { Component, OnInit, Input, Inject, ChangeDetectorRef, Output, EventEmitter, AfterViewInit, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'kt-news-dialog',
  templateUrl: './news-filter.component.html',
  styleUrls: ['./news-filter.component.scss']
})
export class NewsFilterDialogComponent implements OnInit {
  newsList: any[];
  tagList: any[];
  selectedKeywords: any[];
  newKeywords: string;
  data: any;
  allWebsites = false;
  allTwitter = false;

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<NewsFilterDialogComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) data) {
      this.data = data;
      this.newsList = data.urlList;
      this.tagList = data.tagList;
      this.selectedKeywords = data.selectedKeywords;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    // this.cdr.detectChanges();
    
  }

  onClickDeleteKeyword(item) {
    const index = this.selectedKeywords.indexOf(item);
    this.selectedKeywords.splice(index, 1);
  }

  onChangeNewKeyword() {
    const keywords = this.newKeywords.split(',');
    for (let key of keywords) {
      if (!this.selectedKeywords.includes(key)) {
        this.selectedKeywords.push(key.trim());
      }
    }
    this.newKeywords = '';
  }

}
