<div class="card card-custom ">
    <div class="card-body row flex-row justify-content-between">
        <!-- <h4 class="card-title">Title</h4> -->
        <div class="col-3 text-center">
            <div class="d-flex justify-content-center">
                <div>
                    <div class="symbol symbol-100 symbol-light-success">
                        <span class="symbol-label font-size-h1 font-weight-bold">{{user.fullname|firstLetter}}</span>
                    </div>
                </div>
            </div>
            <div class="text-muted mt-4 mb-2">
                <span class="mr-2">{{ "AUTH.INPUT.FULLNAME" | translate }}:</span>
                <span>{{user.fullname}}</span>
            </div>
            <div class="text-muted mb-2">
                <span class="mr-2">{{ "AUTH.INPUT.EMAIL" | translate }}:</span>
                <span>{{user.email}}</span>
            </div>
            <div class="text-muted">
                <span class="mr-2">{{ "MISC.CREATED_ON" | translate }}</span>
                <span>{{user.created | date}}</span>
            </div>
            <div class="row form-group mt-8">
                <button type="button" class="btn btn-light-primary btn-block text-uppercase" (click)="onDownloadUserData()">{{ "MISC.DOWNLOAD_USER" | translate }}</button>
            </div>
        </div>
        <div class="col-8 mr-5">
            <div class="row form-group">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">{{ "AUTH.INPUT.FULLNAME" | translate }}</span>
                    </div>
                    <input [(ngModel)]="user.fullname" type="text" class="form-control" id="basic-url1" aria-describedby="basic-addon1">
                </div>
            </div>
            <div class="row form-group">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon2">{{ "AUTH.INPUT.USERNAME" | translate }}</span>
                    </div>
                    <input [(ngModel)]="user.username" disabled type="text" class="form-control" id="basic-url2" aria-describedby="basic-addon2">
                </div>
            </div>
            <div class="row form-group">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon3">{{ "AUTH.INPUT.EMAIL" | translate }}</span>
                    </div>
                    <input [(ngModel)]="user.email" disabled type="text" class="form-control" id="basic-url3" aria-describedby="basic-addon3">
                </div>
            </div>
            <div class="row form-group">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon3">{{ "AUTH.INPUT.PASSWORD" | translate }}</span>
                    </div>
                    <input [(ngModel)]="user.password" type="password" class="form-control" id="basic-url3" aria-describedby="basic-addon3">
                </div>
            </div>
            <div class="row form-group">
                <button type="button" class="btn btn-primary btn-block text-uppercase" (click)="onChangeUserData()">{{ "MISC.UPDATE" | translate }}</button>
            </div>

        </div>
    </div>
</div>