// Angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
// Core Module
import { CoreModule } from '../../../core/core.module';
import { PartialsModule } from '../../partials/partials.module';
import { NewsComponent } from './news.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
// import { NewsFilterDialogComponent } from '../../partials/content/modals/index';
import { MatDialogModule } from '@angular/material/dialog';
import { NewsService } from 'src/app/core/services/news.service';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

@NgModule({
  imports: [
    CommonModule,
    PartialsModule,
    CoreModule,
    TranslateModule.forChild(),
    RouterModule.forChild([
      {
        path: '',
        component: NewsComponent
      },
    ]),
    // ng-bootstrap modules
    NgbDropdownModule,
    NgbTabsetModule,
    NgbTooltipModule,
    FormsModule,
    MatDialogModule,
    PerfectScrollbarModule
  ],
  providers: [NewsService],
  declarations: [
    NewsComponent,
    // NewsFilterDialogComponent
  ],
  // entryComponents: [NewsFilterDialogComponent]
})
export class NewsModule {
}
