<div class="card card-custom" [ngClass]="cssClasses">
    <!-- Header -->
    <div class="card-header border-0">
        <h3 class="card-title font-weight-bolder text-dark">{{title}}</h3>
    </div>
    <!-- Body -->
    <div class="card-body pt-0">
        <!--begin::Item-->
        <div>
            <div [perfectScrollbar]="{wheelPropagation: false}"
                [ngStyle]="{'max-height': '50vh', 'position': 'relative'}">
                <div class="mb-5" *ngFor="let item of newsList">
                    <!--begin::Section-->
                    <div [ngStyle]="{'border-color': item.sentiment.color}"
                        class="pr-4 d-flex align-items-center sem-border w-100">
                        <!--begin::Text-->
                        <div class="d-flex flex-column flex-grow-1 ml-4">
                            <a href={{item.url}} target="_blank"
                                class="font-size font-weight-bold text-dark-75 text-hover-primary mb-1">{{item.title}}</a>
                            <span class="text-muted font-weight-bold">{{item.date_modify | date}}</span>
                        </div>
                        <a class="btn btn-hover-light-primary btn-sm btn-icon" (click)="onClickNews(item)">
                            <i class="ki ki-bold-more-hor"></i>
                        </a>
                        <!--end::Text-->
                    </div>
                    <!--end::Section-->

                    <!--begin::Desc-->
                    <p class="text-dark-50 m-0 pt-5 font-weight-normal">
                        {{item.description}}
                    </p>
                    <!--end::Desc-->
                </div>
            </div>
            <div *ngIf="!newsList || newsList.length == 0">
                {{'NEWS.NO_DATA' | translate}}
            </div>
        </div>

        <!--end::Item-->

    </div>
    <div class="card-footer">
        <div class="d-flex justify-content-center">
            <a class="btn btn-hover-light-primary btn-primary btn-icon col" (click)="onClickMoreNews()">
                <span>{{'NEWS.MORE' | translate}}</span>
            </a>
        </div>
    </div>
</div>