// Angular
import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as wordCloud from '../../charts/wordcloud/wordcloud.component';

import { MatDialog } from '@angular/material/dialog';
import { NewsFilterDialogComponent } from '../../partials/content/modals/index';
import { NewsService } from 'src/app/core/services/news.service';

import * as d3 from "d3-scale-chromatic";
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'kt-news',
    templateUrl: './news.component.html',
    styleUrls: ['news.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class NewsComponent implements OnInit, AfterViewInit {

    newsList: any[] = [];
    urlList: any[] = [];
    tagList: any[] = [];
    selectedKeywords: any[] = [];
    searchTerms: string;
    currentNews: any;
    showAllText = true;
    similarNewsTitle: string;
    filteredNews: boolean = false;

    @ViewChild('valenceScale') valenceScale: ElementRef;
    @ViewChild('valenceMarker') valenceMarker: ElementRef;

    constructor(private cdr: ChangeDetectorRef,
        private newsService: NewsService,
        private route: ActivatedRoute,
        private translate: TranslateService,
        public dialog: MatDialog) { }

    ngAfterViewInit(): void {
        this.onClickNews(this.newsList[0]);
        this.cdr.detectChanges();
    }

    processNews(news, importantNews): void {
        // missing main text
        if (!news.maintext) {
            news.sentiment = {
                sentiment_score: 0
            }
            news.fake_news = {
                confidence: 0,
                class: 3
            }
        }

        // missing sentiment
        if (!news.sentiment) {
            news.sentiment = {
                sentiment_score: 0
            }
        }

        //missing fake news
        if (!news.fake_news) {
            news.fake_news = {
                confidence: 0,
                class: 3
            }
        }


        if (news.sentiment.sentiment_score > 5) {
            news.sentiment.sentiment_score = 5;
        }
        news.sentiment.color = d3.interpolateRdYlGn(news.sentiment.sentiment_score / 5);
        news.fake_news.confidence_percent = (news.fake_news.confidence * 100).toFixed(2) + '%';
        switch (news.fake_news.class) {
            case 0:
                news.fake_news.type = this.translate.instant('NEWS.TRUE');
                break;
            case 1:
                news.fake_news.type = this.translate.instant('NEWS.PLAUSIBLE');
                break;
            case 2:
                news.fake_news.type = this.translate.instant('NEWS.TENDENTIOUS');
                break;
            case 3:
                news.fake_news.type = this.translate.instant('NEWS.FICTIONAL');
                break;
        }

        if (importantNews.includes(news.id)) {
            news.important = true;
        }

        if (news.keywords) {
            news.keywords = news.keywords.split(/[\s,]+/).join(', ');
        } else {
            news.keywords = '';
        }
    }

    getAllNews(): void {
        this.newsService.getWebsiteNews().subscribe(
            result => {
                this.filteredNews = false;
                this.newsList = result.data.articles;
                const importantNews = result.data.important;

                for (let news of this.newsList) {
                    this.processNews(news, importantNews);
                }

                this.onClickNews(this.newsList[0]);
                this.cdr.detectChanges();
            },
            error => {

            }
        );
    }


    ngOnInit(): void {
        this.getAllNews();

        this.newsService.getTwitterTags().subscribe(
            result => {
                this.tagList = result.data;
            },
            error => { }
        );

        this.newsService.getWebsiteUrls().subscribe(
            result => {
                this.urlList = result.data;
            },
            error => { }
        );

        this.route.paramMap.subscribe(
            result => {
                if (result.get('id')) {
                    const id = +result.get('id');
                    this.selectNews(id);
                } else {
                    this.selectNews(0);
                }
            },
            error => {
                console.log(error);
            }
        );
        this.cdr.detectChanges();

    }

    selectNews(id) {
        console.log(id);
        this.onClickNews(this.newsList[0]);
    }

    onClickNews(news) {
        if (!news) {
            return;
        }
        this.currentNews = news;

        const max = this.valenceScale.nativeElement.offsetWidth;

        if (!this.currentNews.sentiment) {
            this.currentNews.sentiment = {
                sentiment_score: 0
            }
        }

        const val = this.currentNews.sentiment.sentiment_score / 5;

        this.valenceMarker.nativeElement.style.left = (max * val) + 'px';
    }

    toggleMainText() {
        this.showAllText = !this.showAllText;
    }

    processSummary() {
        if (!this.currentNews) {
            return;
        }

        if (!this.currentNews.summary) {
            return;
        }

        if (!this.currentNews.summary.hasOwnProperty('summarization')) {
            return;
        }
        return this.highlightKeywords(this.currentNews.summary.summarization);
    }

    processMainText() {
        if (!this.currentNews || !this.currentNews.maintext) {
            return;
        }
        return this.highlightKeywords(this.currentNews.maintext);
    }

    highlightKeywords(text: string) {
        let split = text.split('\n');
        let result = [];

        let splitParagraph = (text) => {
            if (!this.currentNews.keywords) {
                return [{ word: text, highlight: false }];
            }
            const keywords = this.currentNews.keywords.split(/[\s,]+/);
            const splitText = text.split(' ');
            const highlight = [];
            let regular = [];

            for (let key of splitText) {
                if (this.includes(keywords, key)) {
                    highlight.push({ word: regular.join(' '), highlight: false });
                    highlight.push({ word: key, highlight: true });
                    regular = [];
                } else {
                    regular.push(key);
                }
            }

            if (regular) {
                highlight.push({ word: regular.join(' '), highlight: false });
            }

            return highlight;
        }

        let i = 0;
        for (let s of split) {
            if (this.currentNews.sentiment.paragraph) {
                result.push({ paragraphs: splitParagraph(s), style: this.currentNews.sentiment.paragraph[i] });
            } else {
                result.push({ paragraphs: splitParagraph(s), style: 'transparent' });
            }
            i++;

        }

        return result;
    }

    includes(arr: string[], key: string): boolean {
        for (let value of arr) {
            if (!value) {
                continue;
            }
            if (key.toLowerCase().indexOf(value.toLowerCase()) != -1) {
                return true;
            }
        }
        return false;
    }

    onClickFilter() {
        // let selectedKeywords = [];
        for (let tag of this.tagList) {
            tag.checked = true;
        }
        let dialogRef = this.dialog.open(NewsFilterDialogComponent, {
            data: {
                urlList: this.urlList,
                tagList: this.tagList,
                selectedKeywords: this.selectedKeywords,
                width: '600px',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            // const keywords = result.selectedKeywords; ???
            this.selectedKeywords = result.selectedKeywords;
            const urls = [];
            const tags = [];
            for (let url of result.urlList) {
                if (url.checked) {
                    urls.push(url.url);
                }
            }

            for (let tag of result.tagList) {
                if (tag.checked) {
                    tags.push(tag.tag);
                }
            }

            this.getNewsBy();

            console.log(result);
        });
    }

    getNewsBy() {
        console.log(this.searchTerms);
        
        const urls = [];
        const tags = [];

        for (let tag of this.tagList) {
            if (tag.checked) {
                tags.push(tag.tag);
            }

        }
        for (let url of this.urlList) {
            if (url.checked) {
                urls.push(url.url);
            }
        }

        this.newsService.getNewsBy(this.selectedKeywords, urls, tags, this.searchTerms).subscribe(
            result => {
                if (result.success) {
                    if (this.selectedKeywords.length > 0 || urls.length > 0 || tags.length > 0) {
                        this.filteredNews = true;
                    }
                    this.newsList = result.data.articles;
                    const importantNews = result.data.important;

                    for (let news of this.newsList) {
                        this.processNews(news, importantNews);
                    }

                    this.onClickNews(this.newsList[0]);
                    this.cdr.detectChanges();
                }
            },
            error => { }
        );
    }

    onClickRemoveFilter() {
        // remove existing filters
        this.selectedKeywords = [];
        this.uncheckResource(this.tagList);
        this.uncheckResource(this.urlList);
        this.filteredNews = false;

        if (this.searchTerms) {
            this.getNewsBy();
        } else {
            this.getAllNews();
        }
    }

    onClickSearch() {
        if (this.searchTerms) {
            this.getNewsBy();
        } else {
            this.getAllNews();
        }
    }

    uncheckResource(resources: any) {
        for (let res of resources) {
            delete res.checked;
        }
    }

    onClickTextValence() {
        this.newsService.sentimentAnalysis(this.currentNews.maintext, this.currentNews.sentiment.sentiment_score).subscribe(
            result => {
                let colors = [];
                for (let val of result.data) {
                    let color = d3.interpolateRdYlGn(val / 5);
                    color = color.replace('rgb', 'rgba').replace(')', ', 0.2)');
                    colors.push(color);
                }
                this.currentNews.sentiment.paragraph = colors;
                this.cdr.detectChanges();
            },
            error => { }
        );
    }

    onClickImportantNews(item, isImportant) {
        this.newsService.markImportant(item.id).subscribe(result => {
            if (result.success) {
                item.important = isImportant;
                this.cdr.detectChanges();
            }
        });
    }

    onClickSimilarNews() {
        let keywords = this.currentNews.keywords;
        if (!keywords) {
            keywords = this.currentNews.rbkeywords;
        }
        this.newsService.getSimilarNews(this.currentNews.keywords).subscribe(
            result => {
                if (result.success) {
                    this.similarNewsTitle = this.currentNews.title;
                    this.newsList = result.data.articles;
                    const importantNews = result.data.important;

                    for (let news of this.newsList) {
                        this.processNews(news, importantNews);
                    }

                    this.onClickNews(this.newsList[0]);
                    this.cdr.detectChanges();
                }
            },
            error => { }
        );
    }

    onClickBack() {
        this.similarNewsTitle = "";
        this.getAllNews();
    }

}
