// Angular
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
// RxJS
import { Observable, throwError, empty, BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../environments/environment';

import { catchError, switchMap, filter, take } from 'rxjs/operators';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
    private refreshingInProgress: boolean;
    private accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private injector: Injector, private http: HttpClient) { }

    public _refreshToken(): Observable<any> {
        const currentToken = localStorage.getItem(environment.authTokenKey);
        if (currentToken) {
            const httpHeaders = new HttpHeaders();
            httpHeaders.set('Content-Type', 'application/json');
            return this.http.post<any>(environment.BASE_URL + environment.REFRESH_TOKEN,
                { token: currentToken }, { headers: httpHeaders });
        }
        return empty();
    }

    private addAuthorizationHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
        if (token && this.requestNeedsAuth(request.url)) {
            return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
        }

        return request;
    }

    private logoutAndRedirect(err): Observable<HttpEvent<any>> {
        localStorage.removeItem(environment.authTokenKey);
        localStorage.removeItem(environment.refreshTokenKey);
        location.reload(true);

        return throwError(err);
    }

    private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.refreshingInProgress) {
            this.refreshingInProgress = true;
            this.accessTokenSubject.next(null);

            return this._refreshToken().pipe(
                switchMap((res) => {
                    this.refreshingInProgress = false;
                    this.accessTokenSubject.next(res.data);
                    localStorage.setItem(environment.authTokenKey, res.data);
                    // repeat failed request with new token
                    return next.handle(this.addAuthorizationHeader(request, res.data));
                })
            );
        } else {
            // wait while getting new token
            return this.accessTokenSubject.pipe(
                // filter(token => token !== null),
                // take(1),
                switchMap(token => {
                    if (token) {
                        // repeat failed request with new token
                        return next.handle(this.addAuthorizationHeader(request, token));
                    } else {
                        return this.logoutAndRedirect({});
                    }
                }));
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken = localStorage.getItem(environment.authTokenKey);

        return next.handle(this.addAuthorizationHeader(req, accessToken)).pipe(
            catchError(err => {
                // in case of 401 http error
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    // get refresh tokens
                    //   const refreshToken = this.localStorageService.getItem('refreshToken');

                    // if there are tokens then send refresh token request
                    //   if (refreshToken && accessToken) {
                    return this.refreshToken(req, next);
                    //   }

                    // otherwise logout and redirect to login page
                    //   return this.logoutAndRedirect(err);
                }

                // in case of 403 http error (refresh token failed)
                // 422 invalid token
                if (err instanceof HttpErrorResponse && (err.status === 403 || err.status === 422)) {
                    // logout and redirect to login page
                    return this.logoutAndRedirect(err);
                }
                // if error has status neither 401 nor 403 then just return this error
                return throwError(err);
            })
        );
    }

    requestNeedsAuth(url: string): boolean {
        if (!url.includes(environment.BASE_URL)) {
            return false;
        }

        if (url.includes(environment.LOGIN) ||
            url.includes(environment.REGISTER)) {
            return false;
        }
        return true;

    }
}
